/* ---------------------------------------------------------------------- */
/*  Theme 6
/* ---------------------------------------------------------------------- */
.main-content > div.container {
  margin-top: 32px;
}
body {
  background: #f7f7f8;
}
@media (max-width: 991px) {
  .app-content {
    background: #f7f7f8;
  }
}
.ingram-micro-8,
.ingram-micro-8 *:not([class*="ti-"]):not(.fa):not(.far):not(.fas):not(.fal):not(.fab):not(.glyphicon):not(.customFonts) {
  font-family: 'HelveticaNeueLTStd';
}
header .navbar-header {
  background: #fff !important;
  border-right-color: rgba(0, 0, 0, 0.2) !important;
  border-bottom-color: rgba(0, 0, 0, 0.2) !important;
}
header .navbar-header .sidebar-toggler,
header .navbar-header .sidebar-mobile-toggler {
  color: #5c798f !important;
}
header .navbar-header .navbar-brand {
  color: #ffffff !important;
}
header .navbar-header .menu-toggler {
  color: #5c798f !important;
}
header .navbar-collapse {
  background: #ffffff !important;
  border-bottom-color: rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2) !important;
}
header .navbar-collapse .navbar-right > li > a {
  color: #141719;
}
header .navbar-collapse .navbar-right > li > a i {
  color: #3E4B5C !important;
}
header .navbar-collapse .navbar-right > li > a:hover,
header .navbar-collapse .navbar-right > li > a:focus,
header .navbar-collapse .navbar-right > li > a:active {
  background: #ebebeb !important;
}
header .navbar-collapse .navbar-right > li.open > a {
  background: #ebebeb !important;
}
header .navbar-collapse .navbar-right .dot-badge {
  border-color: #ffffff;
}
@media (max-width: 991px) {
  header .navbar-collapse {
    background: #fff !important;
    box-shadow: none;
  }
  header .navbar-collapse .navbar-right > li > a {
    color: #ffffff;
  }
  header .navbar-collapse .navbar-right > li > a i {
    color: #5c798f !important;
  }
  header .navbar-collapse .navbar-right > li > a:hover,
  header .navbar-collapse .navbar-right > li > a:focus,
  header .navbar-collapse .navbar-right > li > a:active {
    background: #ebebeb !important;
  }
  header .navbar-collapse .navbar-right > li.open > a {
    background: #ebebeb !important;
  }
  header .navbar-collapse .navbar-right .dot-badge {
    border-color: #fff;
  }
}
@media (max-width: 991px) and (max-width: 767px) {
  header .navbar-collapse .navbar-right > li {
    border-bottom: 1px solid #2C2F3B !important;
  }
}
@media (max-width: 767px) {
  header .navbar-collapse {
    background: #f2f2f2 !important;
  }
  header .navbar-collapse .close-handle {
    background: #fff;
  }
  header .navbar-collapse .close-handle .arrow-left,
  header .navbar-collapse .close-handle .arrow-right {
    background-color: #5c798f;
  }
  header .navbar-collapse .navbar-right .current-user a i {
    color: #ffffff !important;
    background: rgba(92, 121, 143, 0.3);
  }
}
#sidebar {
  background: #4a4a4a !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
#sidebar:before {
  border-right-width: 0 !important;
  border-right-style: solid;
  border-right-color: transparent !important;
}
#sidebar nav {
  border-right-width: 0 !important;
  border-right-style: solid;
  border-right-color: transparent !important;
}
#sidebar nav .navbar-title {
  color: #ffffff;
}
#sidebar nav > ul {
  background: #4a4a4a !important;
  border-bottom-color: #4a4a4a !important;
  border-top-color: #4a4a4a !important;
}
#sidebar nav > ul > li a:active:focus {
  background: transparent !important;
}
#sidebar nav > ul > li > a .item-media > i {
  color: #9b9b9b !important;
}
#sidebar nav > ul > li > a .item-media .fa-stack .fa-square {
  color: #404040 !important;
}
#sidebar nav > ul > li > a .item-inner {
  color: #5c798f !important;
  border-bottom-color: #4a4a4a;
}
#sidebar nav > ul > li > a .item-inner-inactive {
  color: #3E4B5C !important;
  border-bottom-color: #4a4a4a;
}
#sidebar nav > ul > li.hover {
  background: #404040 !important;
}
.no-touch #sidebar nav > ul > li:hover {
  background: #404040 !important;
}
#sidebar nav > ul > li.active {
  background: #404040 !important;
}
#sidebar nav > ul > li.active:hover {
  background: #404040 !important;
}
@media (min-width: 769px) {
  #sidebar nav > ul > li.active:before {
    border-right-color: transparent !important;
  }
  #sidebar nav > ul > li.active:after {
    border-right-color: #f7f7f8 !important;
  }
}
#sidebar nav > ul > li.active > a:active:focus {
  background: #333333 !important;
}
#sidebar nav > ul > li.active > a .item-inner {
  color: #ffffff !important;
}
#sidebar nav > ul > li.active > a .item-media > i {
  color: #dbdbdb !important;
}
#sidebar nav > ul > li.active > a .item-media .fa-stack .fa-square {
  color: #007aff !important;
}
#sidebar nav > ul > li.active .sub-menu {
  background: #4a4a4a;
}
#sidebar nav > ul .sub-menu {
  background: #4a4a4a;
}
#sidebar nav > ul .sub-menu > li a {
  color: #dbdbdb;
}
#sidebar nav > ul .sub-menu > li.active a {
  font-weight: bold;
}
#sidebar nav ul > li.hover {
  background: #3d3d3d;
}
.no-touch #sidebar nav ul > li:hover {
  background: #3d3d3d;
}
#sidebar nav .button-o {
  border-color: #9b9b9b !important;
  color: #9b9b9b !important;
}
#sidebar.sonicwall-9 nav > ul {
  background: #68696b !important;
  border-bottom-color: #68696b !important;
  border-top-color: #68696b !important;
}
#sidebar.brandforce-10 nav > ul {
  background: #68696b !important;
  border-bottom-color: #68696b !important;
  border-top-color: #68696b !important;
}
#sidebar.blueequinox-11 nav > ul {
  background: #68696b !important;
  border-bottom-color: #68696b !important;
  border-top-color: #68696b !important;
}
.ingram-micro-8#sidebar nav > ul .hr-line-solid {
  border-top: 1px solid #9b9b9b;
  color: #9b9b9b;
  background-color: #9b9b9b;
}
.ingram-micro-8#sidebar nav > ul > li a {
  color: #9B9B9B;
}
.ingram-micro-8#sidebar nav > ul > li a .item-media > i {
  color: #9B9B9B !important;
}
.ingram-micro-8#sidebar nav > ul > li.active {
  background: #404040 !important;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar {
  background: #2F76BB !important;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar li {
  border-right: 1px solid #4D89C3 !important;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar li.hover {
  background: transparent;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar li:hover {
  background: #3A7EC1 !important;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar li.active {
  background: #F7F7F8 !important;
}
.ingram-micro-8#sidebar nav > ul > li.active .topbar li.active span {
  color: #2F76BB !important;
}
.ingram-micro-8#sidebar nav > ul > li.active a {
  color: #DBDBDB;
}
.ingram-micro-8#sidebar nav > ul > li.active a .item-media > i {
  color: #DBDBDB !important;
}
.sonicwall-9,
.sonicwall-9 *:not([class*="ti-"]):not(.fa):not(.far):not(.fas):not(.fal):not(.fab):not(.glyphicon):not(.customFonts) {
  font-family: 'HelveticaNeueLTStd';
}
.sonicwall-9#sidebar {
  background: #68696b !important;
}
.sonicwall-9#sidebar nav > ul .hr-line-solid {
  border-top: 1px solid #9b9b9b;
  color: #9b9b9b;
  background-color: #9b9b9b;
}
.sonicwall-9#sidebar nav > ul > li a {
  color: #9B9B9B;
}
.sonicwall-9#sidebar nav > ul > li a .item-media > i {
  color: #9B9B9B !important;
}
.sonicwall-9#sidebar nav > ul > li.active {
  background-color: #535456 !important;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar {
  background: #06293d !important;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar li {
  border-right: 1px solid #68696b !important;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar li.hover {
  background: transparent;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar li:hover {
  background: #123549 !important;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar li.active {
  background: #F7F7F8 !important;
}
.sonicwall-9#sidebar nav > ul > li.active .topbar li.active span {
  color: #06293d !important;
}
.sonicwall-9#sidebar nav > ul > li.active a {
  color: #DBDBDB;
}
.sonicwall-9#sidebar nav > ul > li.active a .item-media > i {
  color: #DBDBDB !important;
}
.no-touch .sonicwall-9#sidebar nav > ul > li:hover {
  background-color: #535456 !important;
}
.brandforce-10,
.brandforce-10 *:not([class*="ti-"]):not(.fa):not(.far):not(.fas):not(.fal):not(.fab):not(.glyphicon):not(.customFonts) {
  font-family: 'HelveticaNeueLTStd';
}
.brandforce-10#sidebar {
  background: #68696b !important;
}
.brandforce-10#sidebar nav > ul .hr-line-solid {
  border-top: 1px solid #9b9b9b;
  color: #9b9b9b;
  background-color: #9b9b9b;
}
.brandforce-10#sidebar nav > ul > li a {
  color: #9B9B9B;
}
.brandforce-10#sidebar nav > ul > li a .item-media > i {
  color: #9B9B9B !important;
}
.brandforce-10#sidebar nav > ul > li.active {
  background-color: #535456 !important;
}
.brandforce-10#sidebar nav > ul > li.active .topbar {
  background: #06293d !important;
}
.brandforce-10#sidebar nav > ul > li.active .topbar li {
  border-right: 1px solid #68696b !important;
}
.brandforce-10#sidebar nav > ul > li.active .topbar li.hover {
  background: transparent;
}
.brandforce-10#sidebar nav > ul > li.active .topbar li:hover {
  background: #123549 !important;
}
.brandforce-10#sidebar nav > ul > li.active .topbar li.active {
  background: #F7F7F8 !important;
}
.brandforce-10#sidebar nav > ul > li.active .topbar li.active span {
  color: #06293d !important;
}
.brandforce-10#sidebar nav > ul > li.active a {
  color: #DBDBDB;
}
.brandforce-10#sidebar nav > ul > li.active a .item-media > i {
  color: #DBDBDB !important;
}
.no-touch .brandforce-10#sidebar nav > ul > li:hover {
  background-color: #535456 !important;
}
.blueequinox-11,
.blueequinox-11 *:not([class*="ti-"]):not(.fa):not(.far):not(.fas):not(.fal):not(.fab):not(.glyphicon):not(.customFonts) {
  font-family: 'HelveticaNeueLTStd';
}
.blueequinox-11#sidebar {
  background: #68696b !important;
}
.blueequinox-11#sidebar nav > ul .hr-line-solid {
  border-top: 1px solid #9b9b9b;
  color: #9b9b9b;
  background-color: #9b9b9b;
}
.blueequinox-11#sidebar nav > ul > li a {
  color: #9B9B9B;
}
.blueequinox-11#sidebar nav > ul > li a .item-media > i {
  color: #9B9B9B !important;
}
.blueequinox-11#sidebar nav > ul > li.active {
  background-color: #535456 !important;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar {
  background: #2A3D46 !important;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar li {
  border-right: 1px solid #68696b !important;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar li.hover {
  background: transparent;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar li:hover {
  background: #123549 !important;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar li.active {
  background: #F7F7F8 !important;
}
.blueequinox-11#sidebar nav > ul > li.active .topbar li.active span {
  color: #2A3D46 !important;
}
.blueequinox-11#sidebar nav > ul > li.active a {
  color: #DBDBDB;
}
.blueequinox-11#sidebar nav > ul > li.active a .item-media > i {
  color: #DBDBDB !important;
}
.no-touch .blueequinox-11#sidebar nav > ul > li:hover {
  background-color: #535456 !important;
}
@media (min-width: 769px) {
  .app-sidebar-closed .app-aside .search-form {
    background: #3b3b3b !important;
    border-bottom-color: transparent !important;
  }
  .app-sidebar-closed .app-aside .search-form > a {
    color: #dbdbdb;
  }
  .app-sidebar-closed .app-aside .navbar-form {
    background: #3b3b3b !important;
  }
  .app-sidebar-closed .app-aside .navbar-form a {
    color: #dbdbdb !important;
  }
  .app-sidebar-closed .app-aside .navbar-form .form-group input {
    background: rgba(255, 255, 255, 0.4);
  }
  .app-sidebar-closed .app-aside .navbar-form .form-group input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #dbdbdb;
  }
  .app-sidebar-closed .app-aside .navbar-form .form-group input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #dbdbdb;
  }
  .app-sidebar-closed .app-aside .navbar-form .form-group input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #dbdbdb;
  }
  .app-sidebar-closed .app-aside .navbar-form .form-group input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #dbdbdb;
  }
  .app-sidebar-closed .app-aside.ingram-micro-8 > .item-inner {
    background: #0077b4 !important;
  }
  .app-sidebar-closed .app-aside.ingram-micro-8 > .item-inner.active {
    background: #076595 !important;
  }
  .app-sidebar-closed .app-aside.ingram-micro-8 > .item-inner.active:after {
    border-left: 12px solid #0077b4 !important;
  }
  .app-sidebar-closed .app-aside.ingram-micro-8 > .sub-menu li a {
    color: #0077b4 !important;
  }
  .app-sidebar-closed .app-aside > .item-inner {
    background: #b4b4b4 !important;
  }
  .app-sidebar-closed .app-aside > .item-inner .title {
    color: #4a4a4a !important;
  }
  .app-sidebar-closed .app-aside > .item-inner.active {
    background: #828282 !important;
  }
  .app-sidebar-closed .app-aside > .item-inner.active:before {
    border-left: 12px solid transparent;
  }
  .app-sidebar-closed .app-aside > .item-inner.active:after {
    border-left: 12px solid #404040 !important;
  }
  .app-sidebar-closed .app-aside > .item-inner:before {
    border-left: 12px solid transparent;
  }
  .app-sidebar-closed .app-aside > .item-inner:after {
    border-left: 12px solid #ffffff !important;
  }
  .app-sidebar-closed .app-aside .form-group {
    background: #3b3b3b !important;
    border-color: transparent !important;
  }
  .app-sidebar-closed .app-aside .sub-menu {
    background: #4a4a4a;
  }
  .app-sidebar-closed .app-aside .sub-menu li a {
    color: #dbdbdb !important;
  }
  .app-sidebar-closed .app-aside .sub-menu li.active > a,
  .app-sidebar-closed .app-aside .sub-menu li.open > a {
    background: #4a4a4a !important;
  }
}
@media (min-width: 769px) {
  .app-sidebar-closed ul.sub-menu {
    border-right-color: #4a4a4a !important;
    border-bottom-color: #4a4a4a !important;
  }
}
.navbar-form {
  background: #3b3b3b !important;
}
.navbar-form .search-button {
  color: #dbdbdb;
}
.navbar-form .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: rgba(219, 219, 219, 0.5);
}
.navbar-form .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(219, 219, 219, 0.5);
}
.navbar-form .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(219, 219, 219, 0.5);
}
.navbar-form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: rgba(219, 219, 219, 0.5);
}
.navbar-form .form-control:focus {
  background-color: #ffffff !important;
  border-color: #ffffff;
  transition: all 300ms ease-in 0s;
}
.navbar-form .form-control:focus + .search-button {
  color: #aeacb4;
}
.navbar-form .form-control:focus::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #c2c2c5 !important;
}
.navbar-form .form-control:focus:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c2c2c5 !important;
}
.navbar-form .form-control:focus::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c2c2c5 !important;
}
.navbar-form .form-control:focus:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #c2c2c5 !important;
}
.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999 !important;
}
#app > footer {
  background: #fff !important;
  border-top-color: rgba(0, 0, 0, 0.2) !important;
  color: #5c798f;
}
#app > footer.ingram-micro-8 {
  color: #0077b4;
}
#app > footer.sonicwall-9 {
  color: #06293d;
}
#app > footer.brandforce-10 {
  color: #06293d;
}
#app > footer.brandforce-11 {
  color: #2A3D46;
}
#toast-container .toast.toast-info {
  background-color: #34495E;
}
.app-ingram-body #toast-container .toast.toast-info {
  background-color: #2F76BB;
}
.app-analytics-dashboard-mode header#topnav {
  display: none;
}
.app-analytics-dashboard-mode div#sidebar {
  display: none;
}
.app-analytics-dashboard-mode .main-content {
  margin-left: 0;
  top: -65px;
}
.app-analytics-dashboard-mode footer {
  margin-left: 0!important;
}
@media (min-width: 320px) {
  .app-sidebar-closed #sidebar div nav > ul > li {
    padding: 2px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a {
    height: 61px;
    padding: 0px;
    margin: 0 auto;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a i:not([class*="fa-stack"]) {
    font-size: 32px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a i.cstm-icon {
    font-size: 30px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a .item-media {
    display: block;
    text-align: center;
    padding: 13px 15px;
    padding: 0;
    width: 100%;
    margin: 0;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a .item-content {
    display: block;
    padding: 0px;
    padding-top: 9px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li > a .itm-dashboard {
    padding-top: 5px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li .item-content-lable {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
  .app-sidebar-closed #sidebar div nav > ul > li .topbar {
    display: none;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar {
    position: fixed;
    display: block;
    top: 65px;
    left: 75px;
    background: #34495E;
    height: 32px;
    width: 100%;
    padding: 0px;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li {
    border-right: 1px solid #ffffff;
    padding: 7px 50px;
    height: 32px;
    width: auto;
    float: left;
    cursor: pointer;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li a,
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li span {
    color: white;
    text-transform: uppercase;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li:hover {
    background-color: rgba(154, 188, 224, 0.5);
    color: white;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li:hover a,
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li:hover span {
    color: white;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li.active {
    background-color: rgba(154, 188, 224, 0.5);
    color: white;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li.active a,
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li.active span {
    color: white;
  }
}
@media (min-width: 320px) and (min-device-width: 200px) and (max-device-width: 500px) {
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar {
    width: 100vw !important;
    height: auto;
  }
  .app-sidebar-closed #sidebar div nav > ul > li.active .topbar li {
    width: 50%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .app-sidebar-closed #sidebar .active .topbar li {
    padding: 7px 30px !important;
  }
}
@media (min-width: 768px) {
  .app-sidebar-closed #sidebar #buttonleft,
  .app-sidebar-closed #sidebar #buttonright {
    display: none;
  }
}
@media (max-width: 991px) {
  .app-sidebar-closed #sidebar {
    overflow: unset;
  }
  .app-sidebar-closed #sidebar .active .topbar {
    width: 992px !important;
    top: 0px !important;
  }
  .app-sidebar-closed #sidebar .active .topbar li {
    padding: 7px 10px !important;
  }
}
@media (max-width: 767px) {
  .app-sidebar-closed #sidebar #buttonleft,
  .app-sidebar-closed #sidebar #buttonright {
    position: fixed;
    display: block;
    top: 3px;
    z-index: 2;
    width: 25px;
    padding: 3px;
    border-radius: 50%;
    background: #4a4a4a63;
    color: #fff;
    left: 100%;
  }
  .app-sidebar-closed #sidebar #buttonright {
    left: 100px;
  }
}
